<template>
  <TosListVuetify :entityName="entityName" :headers="headers" :validateFunction="validate" >
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Codice" v-model="slotProps.data.codMotivazione" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Descrizione" v-model="slotProps.data.desMotivazione" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="4">
              <v-text-field  :label="tLabel('codice') + '*'" v-model="slotProps.data.codMotivazione" :rules="[rules.required]" :error-messages="slotProps.errors.codMotivazione"/>
            </v-col>
            <v-col cols="4">
              <v-text-field  :label="tLabel('descrizione') + '*'" v-model="slotProps.data.desMotivazione" :rules="[rules.required]" :error-messages="slotProps.errors.desMotivazione" />
            </v-col>
        </v-row>      
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "MotivazioniRifiuto",
  data() {
    return {
      entityName: "motivazioniRifiuto",

      headers: [
        { text: "", value: "action" },
        { text: "Codice", value: "codMotivazione"},
        { text: "Descrizione", value: "desMotivazione" },
      ],
    };
  },
  components: {
    TosListVuetify: TosListVuetify
  },
  mixins: [FormatsMixins],
  methods: {
    validate(item) {
       let errors = {};
       // TODO
       return errors;
    }
  },
};
</script>
